import type { UseProjectsOptions } from './types';
import type { ProjectFiltersResponse, ProjectItem, Projects } from '@/shared/types';
import { projectsFetchLimit } from '@/config/sorting';
import { ProjectIdRouteQueryKey } from '@/composables/map/use-project-fly/constants';

const isExclusiveProjectsEmpty = shallowRef(false);
const isProjectsEmpty = shallowRef(false);

export function useProjects(options?: Partial<UseProjectsOptions>) {
  const route = useRoute();
  const { filtersQueryParams } = useFilterSort();

  const currentProjectsList = useState(`project-${route.name?.toString()}`, () => shallowRef<ProjectItem[]>([]));
  const pageNumber = ref(1);
  const isLoadingMore = ref(false);

  const projectsParams = computed(() => ({
    ...filtersQueryParams.value,
    ...options?.params,
    pageNumber: pageNumber.value,
    pageSize: options?.projectPageSize || projectsFetchLimit,
  }));

  const exclusiveProjectsParams = computed(() => ({
    ...filtersQueryParams.value,
    ...options?.params,
    pageNumber: 1,
    pageSize: options?.exclusivePageSize || 15,
  }));

  const noData = computed(() => isExclusiveProjectsEmpty.value && isProjectsEmpty.value);

  async function fetchProjectList() {
    const { data, status } = await useApi<Projects>('/projects', {
      params: projectsParams,
      onRequest(): Promise<void> | void {
        isLoadingMore.value = pageNumber.value !== 1;
      },
      transform: (projects) => {
        const list = pageNumber.value !== 1 ? [...currentProjectsList.value] : [];
        currentProjectsList.value = [...list, ...projects.data];
        isLoadingMore.value = false;

        return {
          ...projects,
          data: currentProjectsList.value,
        };
      },
    });

    const isLoading = computed(() => !isLoadingMore.value && status.value === 'pending');
    isProjectsEmpty.value = data.value?.data?.length <= 0;

    return {
      projectsList: data,
      isLoading,
    };
  }

  async function fetchExclusiveProjects() {
    const { data, status } = await useApi<ProjectFiltersResponse>('projects/exclusive', {
      params: exclusiveProjectsParams,
    });

    const isLoading = computed(() => status.value === 'pending');
    isExclusiveProjectsEmpty.value = data.value?.data?.length <= 0;

    return {
      exclusiveProjects: data,
      isLoading,
    };
  }

  async function openProjectOnMap({ id }: ProjectItem): Promise<void> {
    await navigateTo({
      name: 'interactive-map',
      query: {
        [ProjectIdRouteQueryKey]: id,
      },
    });
  }

  watch(filtersQueryParams, () => {
    pageNumber.value = 1;
    currentProjectsList.value = [];
  });

  onUnmounted(() => {
    currentProjectsList.value = [];
  });

  return {
    currentProjectsList,
    pageNumber,
    isLoadingMore,
    noData,
    fetchProjectList,
    fetchExclusiveProjects,
    openProjectOnMap,
  };
}
